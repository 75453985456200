import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { Spacer } from "@/components/elemental/spacer/Spacer";
import { typeToItem } from "@/components/shared/registry/All";

export class Rect{
  x = 0;
  y = 0;
  width = 0;
  height = 0;

  constructor (x: number = 0, y: number = 0, width: number = 0, height: number = 0){
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  private limitDecimalPlaces(num: number, decimalPlaces: number): number {
    return parseFloat(num.toFixed(decimalPlaces));
  }

  public getStoreObject(): Object{
    // desired
    const decimalPlaces = 4;
    return {
      x: this.limitDecimalPlaces(this.x, decimalPlaces),
      y: this.limitDecimalPlaces(this.y, decimalPlaces),
      width: this.limitDecimalPlaces(this.width, decimalPlaces),
      height: this.limitDecimalPlaces(this.height, decimalPlaces)
    };
  }

  public fromStoreObject(object: any){
    this.x= object.x;
    this.y= object.y;
    this.width= object.width;
    this.height= object.height;
  }

  equals(rect: Rect){
    return Math.abs(rect.x - this.x)<0.01 &&
           Math.abs(rect.y - this.y)<1 &&
           Math.abs(rect.width - this.width)<0.01 &&
           Math.abs(rect.height - this.height)<1;
  }

  from(rect: Rect){
    this.x = rect.x;
    this.y = rect.y;
    this.width = rect.width;
    this.height = rect.height;
  }

  clone(){
    const newRect = new Rect;
    newRect.from(this);
    return newRect;
  }
}

export
enum AnchorPosition{
  Top,
  Center,
  Bottom,
  None // For root FluidElement
}

export
class Spatial{
  desired = new Rect;
  displayed = new Rect;
  z = 0;
  contentHeight = 0; // content height, set in edit mode, triggered by layoutMinHeightChanged
  contentWidth = 0;
  ratio = 0;
  ifStickToContentHeight = true;
  sourceAnchorVerticalPosition : AnchorPosition = AnchorPosition.None;
  targetAnchorVerticalPosition : AnchorPosition = AnchorPosition.None;

  public getStoreObject(): Object{
    return {
      desired: this.desired.getStoreObject(),
      z: this.z,
      ratio: this.ratio,
      // displayed: this.displayed.getStoreObject(),
      // contentHeight: this.contentHeight,
      // contentWidth: this.contentWidth,
      ifStickToContentHeight: this.ifStickToContentHeight,
      sourceAnchorVerticalPosition: this.sourceAnchorVerticalPosition,
      targetAnchorVerticalPosition: this.targetAnchorVerticalPosition
    };
  }

  public fromStoreObject(object: any){
    this.desired.fromStoreObject(object.desired);
    this.z = object.z;
    // this.displayed.fromStoreObject(object.displayed);
    this.contentHeight = 0;
    this.contentWidth = 0;
    this.ratio = object.ratio === undefined ? 0 : object.ratio;
    this.ifStickToContentHeight = object.ifStickToContentHeight;
    this.sourceAnchorVerticalPosition = object.sourceAnchorVerticalPosition;
    this.targetAnchorVerticalPosition = object.targetAnchorVerticalPosition;
  }

  constructor (desired: Rect, displayed: Rect, contentHeight: number = 0, contentWidth: number = 0, ratio: number = 0, ifStickToContentHeight = false){
    this.desired = desired;
    this.displayed = displayed;
    this.z = 0;
    this.contentHeight = contentHeight;
    this.contentWidth = contentWidth;
    this.ratio = ratio;
    this.ifStickToContentHeight = ifStickToContentHeight;
  }

  ifShouldStickToRatio(){
    return this.ratio !== undefined && this.ratio>0;
  }

  stickToRatio(width: number){
    if (this.ifShouldStickToRatio()){
      this.displayed.height = width * this.ratio;
    }
  }

  ifShouldStickToContentHeight(){
    return !this.ifShouldStickToRatio() && this.ifStickToContentHeight && this.contentHeight>0;
  }

  stickToContentHeight(){
    this.displayed.height = this.contentHeight;
  }

  clone(): Spatial{
    const newSpatial = new Spatial(this.desired.clone(), this.displayed.clone(), this.contentHeight, this.contentWidth, this.ratio, this.ifStickToContentHeight);
    newSpatial.sourceAnchorVerticalPosition = this.sourceAnchorVerticalPosition;
    newSpatial.targetAnchorVerticalPosition = this.targetAnchorVerticalPosition;
    newSpatial.z = this.z;
    return newSpatial;
  }

  equals(spatial: Spatial){
    return spatial.desired.equals(this.desired) &&
           spatial.displayed.equals(this.displayed) &&
           spatial.z === this.z &&
           spatial.contentHeight === this.contentHeight &&
           spatial.contentWidth === this.contentWidth &&
           spatial.ifStickToContentHeight == this.ifStickToContentHeight &&
           spatial.sourceAnchorVerticalPosition === this.sourceAnchorVerticalPosition &&
           spatial.targetAnchorVerticalPosition === this.targetAnchorVerticalPosition;
  }
}

export
class FluidElement extends BaseItem{
  static readonly meta: MetaInfo = {typeName: "FluidElement", friendlyName: "Fluid Element"};

  // desired spatial.
  spatial: Spatial = new Spatial(new Rect,new Rect,96,0);
  item: BaseItem = new Spacer();

  public getStoreObjectItem(): Object{
    return {
      spatial: this.spatial.getStoreObject(),
      item: this.item.getStoreObject()
    };
  }

  public fromStoreObject(object: any): void {
    this.item = typeToItem(object.item.item.type);
    super.fromStoreObject(object);
  }

  public fromStoreObjectItem(item: any){
    this.spatial.fromStoreObject(item.spatial);
    this.item.fromStoreObject(item.item);
  }

  constructor (spatial: Spatial = new Spatial(new Rect,new Rect,96,0), item: BaseItem = new Spacer()){
    super();
    this.spatial = spatial;
    this.item = item;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const fluidElement = item as FluidElement;
    this.spatial = fluidElement.spatial.clone();
    this.item = fluidElement.item.clone(); // must be clone because the type changes
  }

  getMeta(): MetaInfo{
    return FluidElement.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new FluidElement(new Spatial(new Rect,new Rect,96,0), new Spacer());
  }

  public contains(item: BaseItem): BaseItem[]{
    if (this.item.ref === item.ref){
      return [this.item, this];
    }

    const tmp = this.item.contains(item);
    if (tmp.length>0){
      tmp.push(this.item);
      tmp.push(this);
      return tmp;
    }
    return [];
  }
}