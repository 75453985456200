import { BaseItem } from "@/components/shared/model/BaseItem";
import { Rect, Spatial } from "@/components/elemental/fluidContainer/view/shared/FluidElement";
import { PaletteColor } from "@/components/shared/theme/PaletteSet";
import { Language } from '@/components/shared/view/LanguageOption';

import { Container } from "@/components/elemental/container/Container";
import { FluidContainer} from "@/components/elemental/fluidContainer/FluidContainer";
import { Button } from "@/components/elemental/button/Button";
import { Layout } from "@/components/elemental/layout/Layout";
import { Image } from "@/components/elemental/image/Image";
import { Text } from "@/components/elemental/text/Text";
import { Video } from "@/components/elemental/video/Video";
import { Spacer } from "@/components/elemental/spacer/Spacer";

import ContainerDefaultStyle from "@/components/elemental/container/view/default/Style";
import TextDefaultStyle from "@/components/elemental/text/view/default/Style";
import StyleButton from "@/components/elemental/button/view/default/Style";

const template1 = ():BaseItem => {
  const container = new Container();
  const layout = new Layout("Layout");
  const left = new FluidContainer;
  const imageRect = new Rect(1/12,48,10/12,288);
  left.addElementToAnchorTree(new Spatial(imageRect.clone(), imageRect.clone()), new Image());
  const right = new FluidContainer;
  const text = new Text();
  text.setStyle(new TextDefaultStyle());
  const textRect = new Rect(1/12,48,10/12,288);
  right.addElementToAnchorTree(new Spatial(textRect.clone(), textRect.clone()), text);
  const button = new Button();
  button.setStyle(new StyleButton());
  button.setStyleVariantName("Primary");
  const buttonRect = new Rect(4/12,400,4/12,48);
  right.addElementToAnchorTree(new Spatial(buttonRect.clone(), buttonRect.clone()), button);
  const spacerRect = new Rect(4/12,448,4/12,48);
  right.addElementToAnchorTree(new Spatial(spacerRect.clone(), spacerRect.clone()), new Spacer);
  layout.content = left;
  layout.morphToRowAndAddChild(right);

  container.content = layout;
  const containerStyle = new ContainerDefaultStyle;
  containerStyle.contained.backgroundMode.setEnum("Color");
  containerStyle.contained.color.setStyleVariantName("B1");
  container.setStyle(containerStyle);

  return container;
}

import StyleText from "@/components/elemental/text/view/default/Style";

const template2 = ():BaseItem => {
  const container = new Container();
  const containerStyle = new ContainerDefaultStyle();
  containerStyle.contained.contentMaxWidth.setValue('');
  containerStyle.contained.color.setStyleVariantName(PaletteColor.B1);
  container.setStyle(containerStyle);
  container.content = new FluidContainer(Container.nameContent);
  const videoRect = new Rect(0,0,1,496);
  (container.content as FluidContainer).addElementToAnchorTree(new Spatial(videoRect.clone(), videoRect.clone()), new Video());
  const text = new Text(Container.nameContent);
  text.setValueOf(Language.default, '<h1 style="text-align: center;"><span>Design</span></h1>');
  const styleText = new StyleText();
  styleText.font.color.setStyleVariantName(PaletteColor.F1);
  text.setStyle(styleText);
  const textRect = new Rect(0,192,1,96);
  (container.content as FluidContainer).addElementToAnchorTree(new Spatial(textRect.clone(), textRect.clone()), text);
  return container;
}

export
const template3 = ():BaseItem => {
  const container = new Container;
  const containerStyle = new ContainerDefaultStyle();
  containerStyle.contained.contentMaxWidth.setValue('');
  containerStyle.contained.color.setStyleVariantName(PaletteColor.B1);
  container.setStyle(containerStyle);
  container.content = new FluidContainer(Container.nameContent);
  const image = new Image();
  image.setStyleVariantName("");
  image.url.setValueOf(Language.default, 'https://images.unsplash.com/photo-1599499332864-356ea4ffe0fc?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  const imageRect = new Rect(0,0,1,496);
  (container.content as FluidContainer).addElementToAnchorTree(new Spatial(imageRect.clone(), imageRect.clone()), image);
  const text = new Text(Container.nameContent);
  text.setValueOf(Language.default, '<h1 style="text-align: center;"><span>INTRODUCE YOUR BRAND</span></h1>');
  const styleText = new StyleText();
  styleText.font.color.setStyleVariantName(PaletteColor.F1);
  text.setStyle(styleText);
  const textRect = new Rect(0,0,1,496);
  (container.content as FluidContainer).addElementToAnchorTree(new Spatial(textRect.clone(), textRect.clone()), text);

  return container;
}

const templatesText = [template1, template2, template3];

export {templatesText}