export
const getObjectDelta = (obj1: { [key: string]: any }, obj2: { [key: string]: any }): { [key: string]: any } => {
  const delta: { [key: string]: any } = {};

  // Helper function to compare nested objects
  const compareObjects = (o1: { [key: string]: any }, o2: { [key: string]: any }, baseKey: string = '') => {
    for (const key in o1) {
      if (Object.hasOwn(o1, key)) {
        const fullKey = baseKey ? `${baseKey}.${key}` : key;
        if (o1[key] && typeof o1[key] === 'object' && o2[key] && typeof o2[key] === 'object') {
          compareObjects(o1[key], o2[key], fullKey);
        } else if (o1[key] !== o2[key]) {
          delta[fullKey] = { value1: o1[key], value2: o2[key] };
        }
      }
    }

    // Check for properties in o2 that are not in o1
    for (const key in o2) {
      if (Object.hasOwn(o2, key) && !Object.hasOwn(o1, key)) {
        const fullKey = baseKey ? `${baseKey}.${key}` : key;
        delta[fullKey] = { value1: undefined, value2: o2[key] };
      }
    }
  };

  compareObjects(obj1, obj2);

  return delta;
};